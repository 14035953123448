import {OrderActionTypes} from './constants';

export type OrderActionType = {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderActionTypes.RESET
        | OrderActionTypes.ORDERS
        | OrderActionTypes.ORDER
        | OrderActionTypes.CREATE
        | OrderActionTypes.UPDATE
        | OrderActionTypes.DELETE
        | OrderActionTypes.SEARCH
        | OrderActionTypes.RATES
        | OrderActionTypes.PREVIEW_PAYMENT_DETAIL
        | OrderActionTypes.RESET_PREVIEW_DETAIL
        | OrderActionTypes.PAYMENT_STEP_1
        | OrderActionTypes.PAYMENT_STEP_2
        | OrderActionTypes.CREATE_BILL
        | OrderActionTypes.UPDATE_BILL
        | OrderActionTypes.ORDER_BILLS
        | OrderActionTypes.ORDER_BILL
        | OrderActionTypes.CREATE_NOTE;
    payload: {} | string;
};

type Order = {
    id: number;
    name: string;
};

class OrderActions {
    apiResponseSuccess = (actionType: string, data: Order | {}): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): OrderActionType => ({
        type: OrderActionTypes.RESET,
        payload: {},
    });

    getOrders = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDERS,
        payload: {queryParams},
    });

    getOrder = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDER,
        payload: {queryParams},
    });

    create = (data: any): OrderActionType => ({
        type: OrderActionTypes.CREATE,
        payload: {data},
    });

    update = (data: any): OrderActionType => ({
        type: OrderActionTypes.UPDATE,
        payload: {data},
    });

    delete = (id: any): OrderActionType => ({
        type: OrderActionTypes.DELETE,
        payload: {id},
    });

    search = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.SEARCH,
        payload: {queryParams},
    });

    rates = (data: any): OrderActionType => ({
        type: OrderActionTypes.RATES,
        payload: {data},
    });

    previewPaymentDetail = (data: any): OrderActionType => ({
        type: OrderActionTypes.PREVIEW_PAYMENT_DETAIL,
        payload: {data}
    });

    resetPreviewDetail = (): OrderActionType => ({
        type: OrderActionTypes.RESET_PREVIEW_DETAIL,
        payload: {}
    });

    paymentStep1 = (data: any): OrderActionType => ({
        type: OrderActionTypes.PAYMENT_STEP_1,
        payload: {data}
    });

    paymentStep2 = (data: any): OrderActionType => ({
        type: OrderActionTypes.PAYMENT_STEP_2,
        payload: {data}
    });

    createBill = (data: any): OrderActionType => ({
        type: OrderActionTypes.CREATE_BILL,
        payload: {data}
    });

    updateBill = (data: any): OrderActionType => ({
        type: OrderActionTypes.UPDATE_BILL,
        payload: {data}
    });

    getBills = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDER_BILLS,
        payload: {queryParams},
    });

    getBill = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDER_BILL,
        payload: {queryParams},
    });

    createNote = (data: any): OrderActionType => ({
        type: OrderActionTypes.CREATE_NOTE,
        payload: {data}
    });
}

const orderActions = new OrderActions();
export { orderActions };
