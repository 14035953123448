import { SettingActionTypes } from './constants';
import {APICore} from "../../helpers/api/apiCore";

const api = new APICore();

const INIT_STATE = {
    loading: false,
    error: "",
    setting: api.getSession()?.setting || {},
    roleChanged: false,
    settingUpdated: false,
    generalSetting: {}
};

type SettingData = {
    id: number;
    name: string;
};

type SettingActionType = {
    type:
        | SettingActionTypes.API_RESPONSE_SUCCESS
        | SettingActionTypes.API_RESPONSE_ERROR
        | SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | SettingActionTypes.CHANGE_ROLE
        | SettingActionTypes.RESET
        | SettingActionTypes.UPDATE
        | SettingActionTypes.GENERAL_SETTING;
    payload: {
        actionType?: string;
        data?: SettingData | any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    previousPage?: {},
    loading: boolean
};

const Setting = (state: State = INIT_STATE, action: SettingActionType) => {
    switch (action.type) {
        case SettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case SettingActionTypes.CHANGE_ROLE: {
                    return {
                        ...state,
                        setting:action.payload.data,
                        loading: false,
                        error: ""
                    };
                }
                case SettingActionTypes.UPDATE: {
                    return {
                        ...state,
                        settingUpdated:action.payload.data,
                        loading: false,
                        error: ""
                    };
                }
                case SettingActionTypes.GENERAL_SETTING: {
                    return {
                        ...state,
                        generalSetting:action.payload.data,
                        loading: false,
                        error: ""
                    };
                }
                default:
                    return { ...state };
            }

        case SettingActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case SettingActionTypes.CHANGE_ROLE:
                case SettingActionTypes.UPDATE:
                case SettingActionTypes.GENERAL_SETTING: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case SettingActionTypes.CHANGE_ROLE:
                case SettingActionTypes.UPDATE:
                case SettingActionTypes.GENERAL_SETTING: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SettingActionTypes.CHANGE_ROLE:

            return {
                ...state,
                loading: true,
                error: ""
            };


        case SettingActionTypes.RESET:

            return {
                ...state,
                previousPage: "",
                pageChanged: false,
                settingUpdated: false,
                error: "",
                loading: false,
                generalSetting: {}
            };

        case SettingActionTypes.UPDATE:

            return {
                ...state,
                loading: true,
                error: "",
            };
        case SettingActionTypes.GENERAL_SETTING:

            return {
                ...state,
                loading: true,
                error: "",
            };

        default:
            return { ...state };
    }
};

export default Setting;
