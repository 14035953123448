import { all, call, fork, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import {SettingActionTypes, SettingErrors} from "./constants";
import {APICore} from "../../helpers/api/apiCore";
import {settingActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import {AuthActionTypes} from "../auth/constants";
import {authAction} from "../auth/actions";
import { profileActions } from '../myAccount/profile/actions';

type SettingData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        name: string;
        permissions: string[];

        roleName: string,

        data: any
    };
    type: string;
};

function* changeRole({ payload: {roleName} }: SettingData): SagaIterator {
    try {
        const param = {
            query:`mutation ChangeRole(
                $name: String!
            ) {
                changeRole(
                    name: $name
                ){
                    user {
                        id
                        username
                        display_name
                        email
                        email_verified_at
                        mobile
                        mobile_verified_at
                        roles {
                            name
                        }
                        permissions
                        image
                        two_fa_enabled
                        defaultRole {
                            name
                        }
                        language
                        calendar
                        timezone
                        last_login_at
                        original_last_login_at
                        last_login_ip
                    }
                }
            }`,

            variables: {
                "name": roleName,
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, SettingErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const role = pluckResponse(responseCR, "changeRole");

        // update session
        const api = new APICore();
        const session = api.getSession();
        session.user = role.user;
        api.setSession(session);

        yield put(authAction.apiResponseSuccess(AuthActionTypes.LOGIN_USER, session.user));
        // yield put(settingActions.apiResponseSuccess(SettingActionTypes.CHANGE_ROLE, session.setting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(settingActions.apiResponseError(SettingActionTypes.CHANGE_ROLE, error));
        } else {
            yield put(settingActions.apiResponseValidationErrors(SettingActionTypes.CHANGE_ROLE, error));
        }
    }
}

function* refreshUser(): SagaIterator {
    try {
        const param = {
            query:`mutation RefreshRole{
                refreshUser {
                    user {
                        id
                        username
                        display_name
                        email
                        email_verified_at
                        mobile
                        mobile_verified_at
                        roles {
                            name
                        }
                        permissions
                        image
                        two_fa_enabled
                        defaultRole {
                            name
                        }
                        language
                        calendar
                        timezone
                        last_login_at
                        original_last_login_at
                        last_login_ip
                    }
                }
            }`
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, SettingErrors.RESPONSE_200);

        const rRR = response.data;

        checkServerError(rRR);

        const role = pluckResponse(rRR, "refreshUser");

        // update session
        const api = new APICore();
        const session = api.getSession();
        session.user = role.user;
        api.setSession(session);

        yield put(authAction.apiResponseSuccess(AuthActionTypes.LOGIN_USER, session.user));
        // yield put(settingActions.apiResponseSuccess(SettingActionTypes.CHANGE_ROLE, session.setting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(settingActions.apiResponseError(SettingActionTypes.CHANGE_ROLE, error));
        } else {
            yield put(settingActions.apiResponseValidationErrors(SettingActionTypes.CHANGE_ROLE, error));
        }
    }
}

function* getUserAction(): SagaIterator {
    try {
        const param = {
            query:`query UserAction {
                userAction
            }`
        };

        const response: any = yield call(graphql, param, 'user');

        checkResponseError(response, SettingErrors.RESPONSE_200);

        const rUA = response.data;

        checkServerError(rUA);

        const userAction = pluckResponse(rUA, "userAction");

        switch (userAction) {
            case "refresh_user":
                yield put(settingActions.refreshUser());
                break;

            case "refresh_verification":
                yield put(settingActions.refreshUser());
                yield put(profileActions.getCompleteProfile());
                break;

        }

    } catch (error: any) {
        // if(typeof error === "string") {
        //     yield put(settingActions.apiResponseError(SettingActionTypes.GET_USER_ACTION, error));
        // } else {
        //     yield put(settingActions.apiResponseValidationErrors(SettingActionTypes.GET_USER_ACTION, error));
        // }
    }

    yield delay(25000);

    yield put(settingActions.getUserAction());
}

function* update({ payload: {data} }: SettingData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateSetting(
                $intlPaymentFee: Float!
                $cashIncomeFee: Float!
            ) {
                updateSetting(
                    intlPaymentFee: $intlPaymentFee
                    cashIncomeFee: $cashIncomeFee
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, SettingErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateSetting");

        yield put(settingActions.apiResponseSuccess(SettingActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(settingActions.apiResponseError(SettingActionTypes.UPDATE, error));
        } else {
            yield put(settingActions.apiResponseValidationErrors(SettingActionTypes.UPDATE, error));
        }
    }
}

function* generalSetting(): SagaIterator {
    try {
        const param = {
            query:`query GeneralSetting {
                generalSetting {
                    international_payment_fee,
                    cash_income_fee
                }
            }`
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, SettingErrors.RESPONSE_200);

        const rUA = response.data;

        checkServerError(rUA);

        const generalSetting = pluckResponse(rUA, "generalSetting");

        yield put(settingActions.apiResponseSuccess(SettingActionTypes.GENERAL_SETTING, generalSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(settingActions.apiResponseError(SettingActionTypes.GENERAL_SETTING, error));
        } else {
            yield put(settingActions.apiResponseValidationErrors(SettingActionTypes.GENERAL_SETTING, error));
        }
    }
}

export function* watchChangeRole() {
    yield takeEvery(SettingActionTypes.CHANGE_ROLE, changeRole);
}

export function* watchRefreshRole() {
    yield takeEvery(SettingActionTypes.REFRESH_ROLE, refreshUser);
}

function* watchGetUserAction() {
    yield takeLatest(SettingActionTypes.GET_USER_ACTION, getUserAction);
}

function* watchUpdate() {
    yield takeLatest(SettingActionTypes.UPDATE, update);
}

function* watchGeneralSetting() {
    yield takeLatest(SettingActionTypes.GENERAL_SETTING, generalSetting);
}

function* settingSaga() {
    yield all([
        fork(watchChangeRole),
        fork(watchRefreshRole),
        fork(watchGetUserAction),
        fork(watchUpdate),
        fork(watchGeneralSetting),
    ]);
}

export default settingSaga;
