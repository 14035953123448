import {DepositActionTypes} from './constants';

export type DepositActionType = {
    type:
        | DepositActionTypes.API_RESPONSE_SUCCESS
        | DepositActionTypes.API_RESPONSE_ERROR
        | DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DepositActionTypes.FIND_DEPOSIT
        | DepositActionTypes.GET_DEPOSITS
        | DepositActionTypes.GET_DEPOSIT
        | DepositActionTypes.VERIFY_DEPOSIT
        | DepositActionTypes.INSERT_DEPOSIT
        | DepositActionTypes.UPDATE_DEPOSIT

        | DepositActionTypes.INSERT_DEPOSIT_ID

        | DepositActionTypes.OPEN_SEARCH
        | DepositActionTypes.CLOSE_SEARCH
        | DepositActionTypes.SEARCH_DEPOSIT
        | DepositActionTypes.CLEAR_SEARCH

        | DepositActionTypes.RESET
        | DepositActionTypes.MANUAL_DEPOSIT
        | DepositActionTypes.PREVIEW_DEPOSIT_DETAIL
        | DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL

        | DepositActionTypes.INQUIRY_PAYMENT
        | DepositActionTypes.EXPORT
        | DepositActionTypes.RESET_SEARCH
        | DepositActionTypes.GATEWAYS_STATUS
        | DepositActionTypes.TOTAL_DEPOSITS;

    payload: {} | string;
};

type Deposit = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    depositName?: string
}

class DepositActions {

    apiResponseSuccess = (actionType: string, data: Deposit | {}): DepositActionType => ({
        type: DepositActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): DepositActionType => ({
        type: DepositActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): DepositActionType => ({
        type: DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): DepositActionType => ({
        type: DepositActionTypes.RESET,
        payload: {},
    });

    getDeposits = (queryParams: QueryParams): DepositActionType => ({
        type: DepositActionTypes.GET_DEPOSITS,
        payload: { queryParams },
    });

    getDeposit = (id: string): DepositActionType => ({
        type: DepositActionTypes.GET_DEPOSIT,
        payload: { id },
    });

    insertDeposit = (data: any): DepositActionType => ({
        type: DepositActionTypes.INSERT_DEPOSIT,
        payload: { data }
    });

    verifyDeposit = (trackId: any): DepositActionType => ({
        type: DepositActionTypes.VERIFY_DEPOSIT,
        payload: { trackId }
    });

    insertDepositId = (data: any): DepositActionType => ({
        type: DepositActionTypes.INSERT_DEPOSIT_ID,
        payload: { data }
    });

    find = (id: number) : DepositActionType => ({
        type: DepositActionTypes.FIND_DEPOSIT,
        payload: {
            id,
        }
    });

    update = (
        id: number,
        name: string,
        permissions: string[],

    ): DepositActionType => ({
        type: DepositActionTypes.UPDATE_DEPOSIT,
        payload: {
            id,
            name,
            permissions
        }
    });

    openSearch = (): DepositActionType => ({
        type: DepositActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): DepositActionType => ({
        type: DepositActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): DepositActionType => ({
        type: DepositActionTypes.SEARCH_DEPOSIT,
        payload: { queryParams }
    });

    clearSearch = (): DepositActionType => ({
        type: DepositActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    manualDeposit = (data: any): DepositActionType => ({
        type: DepositActionTypes.MANUAL_DEPOSIT,
        payload: { data }
    });

    previewDepositDetail = (data: any): DepositActionType => ({
        type: DepositActionTypes.PREVIEW_DEPOSIT_DETAIL,
        payload: {data}
    });

    clearPreviewDepositDetail = (): DepositActionType => ({
        type: DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL,
        payload: {}
    });

    inquiryPayment = (transactionId: any): DepositActionType => ({
        type: DepositActionTypes.INQUIRY_PAYMENT,
        payload: {transactionId}
    });

    export = (queryParams: QueryParams): DepositActionType => ({
        type: DepositActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): DepositActionType => ({
        type: DepositActionTypes.RESET_SEARCH,
        payload: {}
    });

    gatewaysStatus = (): DepositActionType => ({
        type: DepositActionTypes.GATEWAYS_STATUS,
        payload: {},
    });

    totalGatewayDeposits = (): DepositActionType => ({
        type: DepositActionTypes.TOTAL_DEPOSITS,
        payload: {},
    });
}

const depositActions = new DepositActions();
export { depositActions };
