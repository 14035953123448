import { CashIncomeActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    orders: [],
    order: {},
    inserted: false,
    updated: false,
    review: {},
    canceled: false,
};

type CashIncomeActionType = {
    type:
        | CashIncomeActionTypes.API_RESPONSE_SUCCESS
        | CashIncomeActionTypes.API_RESPONSE_ERROR
        | CashIncomeActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CashIncomeActionTypes.ORDERS
        | CashIncomeActionTypes.ORDER
        | CashIncomeActionTypes.CREATE
        | CashIncomeActionTypes.UPDATE
        | CashIncomeActionTypes.RESET
        | CashIncomeActionTypes.REVIEW
        | CashIncomeActionTypes.CANCEL;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    orders: string[],
    order: any
};

const OrderCashIncome = (state: State = INIT_STATE, action: CashIncomeActionType) => {
    switch (action.type) {
        case CashIncomeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case CashIncomeActionTypes.ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data,
                        loading: false,
                    };
                }

                case CashIncomeActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: false,
                    };
                }

                case CashIncomeActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: false,
                    };
                }

                case CashIncomeActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                case CashIncomeActionTypes.REVIEW: {
                    return {
                        ...state,
                        review: action.payload.data,
                        loading: false,
                    };
                }
                case CashIncomeActionTypes.CANCEL: {
                    return {
                        ...state,
                        canceled: action.payload.data,
                        order: {
                            ...state.order,
                            status: "canceled"
                        },
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CashIncomeActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case CashIncomeActionTypes.ORDERS:
                case CashIncomeActionTypes.ORDER:
                case CashIncomeActionTypes.CREATE:
                case CashIncomeActionTypes.UPDATE:
                case CashIncomeActionTypes.REVIEW:
                case CashIncomeActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case CashIncomeActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case CashIncomeActionTypes.ORDERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case CashIncomeActionTypes.ORDER:
                case CashIncomeActionTypes.CREATE:
                case CashIncomeActionTypes.UPDATE:
                case CashIncomeActionTypes.REVIEW:
                case CashIncomeActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CashIncomeActionTypes.ORDERS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case CashIncomeActionTypes.ORDER:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case CashIncomeActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case CashIncomeActionTypes.UPDATE:
            return {
                ...state,
                updated: false,
                loading: true,
                error: "",
            };

        case CashIncomeActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                orders: [],
                order: {},
                inserted: false,
                updated: false,
                review: {},
                canceled: false
            };

        case CashIncomeActionTypes.REVIEW:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case CashIncomeActionTypes.CANCEL:
            return {
                ...state,
                loading: true,
                error: "",
            };

        default:
            return { ...state };
    }
};

export default OrderCashIncome;
