export enum BillActionTypes {
    API_RESPONSE_SUCCESS = '@@bill/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@bill/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@bill/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@bill/RESET',

    BILLS = '@@bill/BILLS',
    BILL = '@@bill/BILL',

    PAY_VIA_GATEWAY = '@@bill/PAY_VIA_GATEWAY',
    PAY_VIA_WALLET = '@@bill/PAY_VIA_WALLET',
}

export enum BillErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



