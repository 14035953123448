import { IntlPaymentActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    orders: [],
    order: {},
    inserted: false,
    updated: false,
    review: {},
    canceled: false,
};

type IntlPaymentActionType = {
    type:
        | IntlPaymentActionTypes.API_RESPONSE_SUCCESS
        | IntlPaymentActionTypes.API_RESPONSE_ERROR
        | IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | IntlPaymentActionTypes.ORDERS
        | IntlPaymentActionTypes.ORDER
        | IntlPaymentActionTypes.CREATE
        | IntlPaymentActionTypes.UPDATE
        | IntlPaymentActionTypes.RESET
        | IntlPaymentActionTypes.REVIEW
        | IntlPaymentActionTypes.CANCEL;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    orders: string[],
    order: any
};

const OrderIntlPayment = (state: State = INIT_STATE, action: IntlPaymentActionType) => {
    switch (action.type) {
        case IntlPaymentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data,
                        loading: false,
                    };
                }

                case IntlPaymentActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: false,
                    };
                }

                case IntlPaymentActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: action.payload.data,
                        loading: false,
                    };
                }

                case IntlPaymentActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                case IntlPaymentActionTypes.REVIEW: {
                    return {
                        ...state,
                        review: action.payload.data,
                        loading: false,
                    };
                }
                case IntlPaymentActionTypes.CANCEL: {
                    return {
                        ...state,
                        canceled: action.payload.data,
                        order: {
                            ...state.order,
                            status: "canceled"
                        },
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDERS:
                case IntlPaymentActionTypes.ORDER:
                case IntlPaymentActionTypes.CREATE:
                case IntlPaymentActionTypes.UPDATE:
                case IntlPaymentActionTypes.REVIEW:
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case IntlPaymentActionTypes.ORDERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case IntlPaymentActionTypes.ORDER:
                case IntlPaymentActionTypes.CREATE:
                case IntlPaymentActionTypes.UPDATE:
                case IntlPaymentActionTypes.REVIEW:
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.ORDERS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case IntlPaymentActionTypes.ORDER:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case IntlPaymentActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case IntlPaymentActionTypes.UPDATE:
            return {
                ...state,
                updated: false,
                loading: true,
                error: "",
            };

        case IntlPaymentActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                orders: [],
                order: {},
                inserted: false,
                updated: false,
                review: {},
                canceled: false
            };

        case IntlPaymentActionTypes.REVIEW:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case IntlPaymentActionTypes.CANCEL:
            return {
                ...state,
                loading: true,
                error: "",
            };

        default:
            return { ...state };
    }
};

export default OrderIntlPayment;
