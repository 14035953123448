import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CashIncomeActionTypes, CashIncomeErrors} from "./constants";
import {cashIncomeActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type CashIncomeData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            cashIncomeBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* orders({ payload: { queryParams } }: CashIncomeData): SagaIterator {
    try {
        const param = {
            query:`query Orders {
                orders {
                    id
                    user {
                        username
                    }
                    created_at
                    updated_at
                    amount
                    rate
                    fee
                    discount
                    data
                    name
                    final_amount
                    status
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        const orders = pluckResponse(rCashIncome, "orders");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.ORDERS, orders));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.ORDERS, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.ORDERS, error));
        }
    }
}

function* order({ payload: { queryParams } }: CashIncomeData): SagaIterator {
    try {
        const param = {
            query:`query OrderCashIncome($id: String!) {
                orderCashIncome(id: $id) {
                    id
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        username
                        password
                    }
                    rate
                    fee
                    discount
                    name
                    final_amount
                    status
                    bills {
                        id
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        const order = pluckResponse(rCashIncome, "orderCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.ORDER, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: CashIncomeData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateCashIncome(
                $amount: String!,
                $currency: String!,
                $url: String!,
                $companyName: String,
                $username: String,
                $password: String,
                $acceptConditions: String!,
                $descriptions: String,
            ) {
                createCashIncome(
                    amount: $amount,
                    currency: $currency,
                    companyName: $companyName,
                    url: $url,
                    username: $username,
                    password: $password,
                    acceptConditions: $acceptConditions,
                    descriptions: $descriptions,
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        const createCashIncome = pluckResponse(rCashIncome, "createCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.CREATE, createCashIncome));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.CREATE, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: CashIncomeData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateCashIncome(
                $id: String!,
                $amount: String!,
                $currency: String!,
                $url: String!,
                $companyName: String,
                $username: String,
                $password: String,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updateCashIncome(
                    id: $id,
                    amount: $amount,
                    currency: $currency,
                    url: $url,
                    companyName: $companyName,
                    username: $username,
                    password: $password,
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        pluckResponse(rCashIncome, "updateCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.UPDATE, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {id} }: CashIncomeData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelCashIncome(
                $id: String!
            ) {
                cancelCashIncome(
                    id: $id
                )
            }`,

            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        pluckResponse(rCashIncome, "cancelCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.CANCEL, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.CANCEL, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: CashIncomeData): SagaIterator {
    try {
        const param = {
            query:`query CashIncomeReview($amount: String!, $currency: String!) {
                cashIncomeReview(amount: $amount, currency: $currency) {
                    amount
                    rate
                    fee
                    final_amount
                }
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const cashIncomeReview = pluckResponse(rOPR, "cashIncomeReview");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.REVIEW, cashIncomeReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.REVIEW, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.REVIEW, error));
        }
    }
}


export function* watchOrders() {
    yield takeEvery(CashIncomeActionTypes.ORDERS, orders);
}

export function* watchOrder() {
    yield takeEvery(CashIncomeActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(CashIncomeActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(CashIncomeActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(CashIncomeActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(CashIncomeActionTypes.REVIEW, review);
}

function* cashIncomeSaga() {
    yield all([
        fork(watchOrders),
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
    ]);
}

export default cashIncomeSaga;
