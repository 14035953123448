export enum IntlPaymentActionTypes {
    API_RESPONSE_SUCCESS = '@@intlPayment/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@intlPayment/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@intlPayment/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@intlPayment/RESET',

    ORDERS = '@@intlPayment/ORDERS',
    ORDER = '@@intlPayment/ORDER',
    CREATE = '@@intlPayment/CREATE',
    UPDATE = '@@intlPayment/UPDATE',
    SEARCH = '@@intlPayment/SEARCH',

    REVIEW = '@@intlPayment/REVIEW',

    CANCEL = '@@intlPayment/CANCEL',
}

export enum IntlPaymentErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



