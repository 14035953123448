import {WithdrawActionTypes} from './constants';

export type WithdrawActionType = {
    type:
        | WithdrawActionTypes.API_RESPONSE_SUCCESS
        | WithdrawActionTypes.API_RESPONSE_ERROR
        | WithdrawActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | WithdrawActionTypes.FIND_WITHDRAW
        | WithdrawActionTypes.GET_WITHDRAWS
        | WithdrawActionTypes.GET_WITHDRAW
        | WithdrawActionTypes.VERIFY_WITHDRAW
        | WithdrawActionTypes.INSERT_WITHDRAW
        | WithdrawActionTypes.CHECK_WITHDRAW
        | WithdrawActionTypes.UPDATE_WITHDRAW
        | WithdrawActionTypes.MANUAL_WITHDRAW
        | WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL

        | WithdrawActionTypes.OPEN_SEARCH
        | WithdrawActionTypes.CLOSE_SEARCH
        | WithdrawActionTypes.SEARCH_WITHDRAW
        | WithdrawActionTypes.CLEAR_SEARCH

        | WithdrawActionTypes.CLEAR_CHECKED

        | WithdrawActionTypes.GET_WITHDRAW_REQUESTS

        | WithdrawActionTypes.RESET
        | WithdrawActionTypes.EXPORT
        | WithdrawActionTypes.RESET_SEARCH
        | WithdrawActionTypes.TOTAL_WITHDRAW;
    payload: {} | string;
};

type Withdraw = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    withdrawName?: string
}

class WithdrawActions {

    apiResponseSuccess = (actionType: string, data: Withdraw | {}): WithdrawActionType => ({
        type: WithdrawActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): WithdrawActionType => ({
        type: WithdrawActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): WithdrawActionType => ({
        type: WithdrawActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): WithdrawActionType => ({
        type: WithdrawActionTypes.RESET,
        payload: {},
    });

    getWithdraws = (queryParams: QueryParams): WithdrawActionType => ({
        type: WithdrawActionTypes.GET_WITHDRAWS,
        payload: { queryParams },
    });

    getWithdrawRequests = (queryParams: QueryParams): WithdrawActionType => ({
        type: WithdrawActionTypes.GET_WITHDRAW_REQUESTS,
        payload: { queryParams },
    });

    getWithdraw = (id: string): WithdrawActionType => ({
        type: WithdrawActionTypes.GET_WITHDRAW,
        payload: { id },
    });

    insertWithdraw = (data: any): WithdrawActionType => ({
        type: WithdrawActionTypes.INSERT_WITHDRAW,
        payload: { data }
    });

    verifyWithdraw = (data: any): WithdrawActionType => ({
        type: WithdrawActionTypes.VERIFY_WITHDRAW,
        payload: { data }
    });

    find = (id: number) : WithdrawActionType => ({
        type: WithdrawActionTypes.FIND_WITHDRAW,
        payload: { id }
    });

    update = (
        id: number,
        name: string,
        permissions: string[],

    ): WithdrawActionType => ({
        type: WithdrawActionTypes.UPDATE_WITHDRAW,
        payload: {
            id,
            name,
            permissions
        }
    });



    openSearch = (): WithdrawActionType => ({
        type: WithdrawActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): WithdrawActionType => ({
        type: WithdrawActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): WithdrawActionType => ({
        type: WithdrawActionTypes.SEARCH_WITHDRAW,
        payload: { queryParams }
    });

    clearSearch = (): WithdrawActionType => ({
        type: WithdrawActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    checkWithdraw = (data: any): WithdrawActionType => ({
        type: WithdrawActionTypes.CHECK_WITHDRAW,
        payload: { data }
    });

    manualWithdraw = (data: any): WithdrawActionType => ({
        type: WithdrawActionTypes.MANUAL_WITHDRAW,
        payload: {data}
    });

    previewWithdrawDetail = (data: any): WithdrawActionType => ({
        type: WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL,
        payload: {data}
    });

    clearChecked = (): WithdrawActionType => ({
        type: WithdrawActionTypes.CLEAR_CHECKED,
        payload: {}
    });

    export = (queryParams: QueryParams): WithdrawActionType => ({
        type: WithdrawActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): WithdrawActionType => ({
        type: WithdrawActionTypes.RESET_SEARCH,
        payload: {}
    });

    totalWithdraws = (): WithdrawActionType => ({
        type: WithdrawActionTypes.TOTAL_WITHDRAW,
        payload: {}
    });
}

const withdrawActions = new WithdrawActions();
export { withdrawActions };
