import {BillActionTypes} from './constants';

export type BillActionType = {
    type:
        | BillActionTypes.API_RESPONSE_SUCCESS
        | BillActionTypes.API_RESPONSE_ERROR
        | BillActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | BillActionTypes.RESET
        | BillActionTypes.BILLS
        | BillActionTypes.BILL
        | BillActionTypes.PAY_VIA_GATEWAY
        | BillActionTypes.PAY_VIA_WALLET;
    payload: {} | string;
};

type Bill = {
    id: number;
    name: string;
};

class BillActions {
    apiResponseSuccess = (actionType: string, data: Bill | {}): BillActionType => ({
        type: BillActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): BillActionType => ({
        type: BillActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): BillActionType => ({
        type: BillActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): BillActionType => ({
        type: BillActionTypes.RESET,
        payload: {},
    });

    getBills = (queryParams: any): BillActionType => ({
        type: BillActionTypes.BILLS,
        payload: {queryParams},
    });

    getBill = (queryParams: any): BillActionType => ({
        type: BillActionTypes.BILL,
        payload: {queryParams},
    });

    payViaGateway = (data: any): BillActionType => ({
        type: BillActionTypes.PAY_VIA_GATEWAY,
        payload: {data},
    });

    payViaWallet = (data: any): BillActionType => ({
        type: BillActionTypes.PAY_VIA_WALLET,
        payload: {data},
    });
}

const billActions = new BillActions();
export { billActions };
