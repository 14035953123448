import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {OrderActionTypes, OrderErrors} from "./constants";
import {orderActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type OrderData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* orders({ payload: { queryParams } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query Orders(
                $page: Int!,
                $limit: Int!,
                $orderBy: String!,
                $sort: String!,
                $accountId: String
            ) {
                orders(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                    accountId: $accountId,
                ) {
                    data {
                        id
                        created_at
                        amount
                        currency
                        rate
                        name
                        user {
                            id
                            username
                            display_name
                        }
                        final_amount
                        status
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                    
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const orders = pluckResponse(rOrder, "orders");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.ORDERS, orders));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.ORDERS, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.ORDERS, error));
        }
    }
}

function* order({ payload: { queryParams } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query Order($id: String!) {
                order(id: $id) {
                    id
                    created_at
                    amount
                    rate
                    name
                    final_amount
                    status
                    user {
                        id
                        username
                        display_name
                    }
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const order = pluckResponse(rOrder, "order");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.ORDER, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateOrder(
                $unit: String!,
                $method: String!,
                $status: String!,
                $rate: String,
                $sourceRate: String,
                $fixed: String,
                $expireTime: String
                $provider: String,
                $buyPercent: String
                $sellPercent: String
            ) {
                createOrder(
                    unit: $unit,
                    method: $method,
                    rate: $rate,
                    status: $status,
                    sourceRate: $sourceRate,
                    fixed: $fixed,
                    expireTime: $expireTime,
                    provider: $provider,
                    buyPercent: $buyPercent,
                    sellPercent: $sellPercent
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const createOrder = pluckResponse(rOrder, "createOrder");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.CREATE, createOrder));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.CREATE, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateOrder(
                $id: String!,
                $unit: String!,
                $method: String!,
                $status: String!,
                $rate: String,
                $sourceRate: String,
                $fixed: String,
                $expireTime: String
                $provider: String
                $buyPercent: String
                $sellPercent: String
            ) {
                updateOrder(
                    id: $id,
                    unit: $unit,
                    method: $method,
                    status: $status,
                    rate: $rate,
                    sourceRate: $sourceRate,
                    fixed: $fixed,
                    expireTime: $expireTime,
                    provider: $provider,
                    buyPercent: $buyPercent,
                    sellPercent: $sellPercent
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        pluckResponse(rOrder, "updateOrder");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.UPDATE, data.unit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.UPDATE, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.UPDATE, error));
        }
    }
}

function* remove({ payload: {id} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation DeleteOrder(
                $id: String!
            ) {
                deleteOrder(
                    id: $id
                )
            }`,

            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        pluckResponse(rOrder, "deleteOrder");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.DELETE, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.DELETE, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.DELETE, error));
        }
    }
}

function* rates({ payload: { data } }: OrderData): SagaIterator {

    try {
        const param = {
            query:`query OrderRates($currencies: [String], $type: String) {
                orderRates(currencies: $currencies, type: $type) {
                    name
                    rate
                }
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const orderRates = pluckResponse(rOPR, "orderRates");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.RATES, orderRates));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.RATES, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.RATES, error));
        }
    }
}

function* previewPaymentDetail({ payload: { data } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query PreviewOrderPaymentDetail(
                $type: String!,
                $fee: String!,
                $wallet: String!,
                $amount: String!
            ) {
                previewOrderPaymentDetail(
                    type: $type,
                    fee: $fee,
                    wallet: $wallet,
                    amount: $amount
                ) {
                    usdRate
                    subTotal
                    fee
                    total
                }
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rED = response.data;

        checkServerError(rED);

        const previewExchangeDetail = pluckResponse(rED, "previewOrderPaymentDetail");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.PREVIEW_PAYMENT_DETAIL, previewExchangeDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.PREVIEW_PAYMENT_DETAIL, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.PREVIEW_PAYMENT_DETAIL, error));
        }
    }
}

function* paymentStep1({ payload: { data } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query OrderPaymentStep1(
                $orderId: String!,
                $type: String!,
                $wallet: String!,
                $amount: String!,
                $fee: String!,
                $description: String
            ) {
                orderPaymentStep1(
                    orderId: $orderId
                    type: $type
                    wallet: $wallet
                    amount: $amount
                    fee: $fee
                    description: $description
                )
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rED = response.data;

        checkServerError(rED);

        pluckResponse(rED, "orderPaymentStep1");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.PAYMENT_STEP_1, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.PAYMENT_STEP_1, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.PAYMENT_STEP_1, error));
        }
    }
}

function* paymentStep2({ payload: { data } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`mutation OrderPaymentStep2(
                $orderId: String!,
                $type: String!,
                $wallet: String!,
                $amount: String!,
                $fee: String!,
                $otp: String!,
                $twoFaOtp: String,
                $description: String
            ) {
                orderPaymentStep2(
                    orderId: $orderId
                    type: $type
                    wallet: $wallet
                    amount: $amount
                    fee: $fee
                    otp: $otp
                    twoFaOtp: $twoFaOtp
                    description: $description
                )
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rED = response.data;

        checkServerError(rED);

        pluckResponse(rED, "orderPaymentStep2");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.PAYMENT_STEP_2, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.PAYMENT_STEP_2, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.PAYMENT_STEP_2, error));
        }
    }
}

function* createBill({ payload: {data} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateOrderBill(
                $orderId: String!,
                $amount: String!,
                $currency: String!,
                $description: String
            ) {
                createOrderBill(
                    orderId: $orderId,
                    amount: $amount,
                    currency: $currency,
                    description: $description
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const createOrderBill = pluckResponse(rOrder, "createOrderBill");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.CREATE_BILL, createOrderBill));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.CREATE_BILL, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.CREATE_BILL, error));
        }
    }
}

function* orderBills({ payload: { queryParams } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query OrderBills(
                $page: Int!,
                $limit: Int!,
                $orderBy: String!,
                $sort: String!,
            ) {
                orderBills(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                ) {
                    data {
                        id
                        created_at
                        amount
                        rate
                        name
                        final_amount
                        status
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                    
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const orderBills = pluckResponse(rOrder, "orderBills");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.ORDER_BILLS, orderBills));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.ORDER_BILLS, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.ORDER_BILLS, error));
        }
    }
}

function* orderBill({ payload: { queryParams } }: OrderData): SagaIterator {
    try {
        const param = {
            query:`query OrderBill($id: String!) {
                orderBill(id: $id) {
                    id
                    created_at
                    amount
                    rate
                    name
                    final_amount
                    status
                    user {
                        id
                        username
                        display_name
                    }
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const orderBill = pluckResponse(rOrder, "orderBill");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.ORDER_BILL, orderBill));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.ORDER_BILL, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.ORDER_BILL, error));
        }
    }
}

function* updateBill({ payload: {data} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateOrder(
                $unit: String!,
                $method: String!,
                $status: String!,
                $rate: String,
                $sourceRate: String,
                $fixed: String,
                $expireTime: String
                $provider: String,
                $buyPercent: String
                $sellPercent: String
            ) {
                createOrder(
                    unit: $unit,
                    method: $method,
                    rate: $rate,
                    status: $status,
                    sourceRate: $sourceRate,
                    fixed: $fixed,
                    expireTime: $expireTime,
                    provider: $provider,
                    buyPercent: $buyPercent,
                    sellPercent: $sellPercent
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const createOrder = pluckResponse(rOrder, "createOrder");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.CREATE, createOrder));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.CREATE, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.CREATE, error));
        }
    }
}

function* createNote({ payload: {data} }: OrderData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateOrderNote(
                $orderId: String!,
                $note: String!
            ) {
                createOrderNote(
                    orderId: $orderId,
                    note: $note
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderErrors.RESPONSE_200);

        const rOrder = response.data;

        checkServerError(rOrder);

        const createOrderNote = pluckResponse(rOrder, "createOrderNote");

        yield put(orderActions.apiResponseSuccess(OrderActionTypes.CREATE_NOTE, createOrderNote));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderActions.apiResponseError(OrderActionTypes.CREATE_NOTE, error));
        } else {
            yield put(orderActions.apiResponseValidationErrors(OrderActionTypes.CREATE_NOTE, error));
        }
    }
}

export function* watchOrders() {
    yield takeEvery(OrderActionTypes.ORDERS, orders);
}

export function* watchOrder() {
    yield takeEvery(OrderActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(OrderActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(OrderActionTypes.UPDATE, update);
}

export function* watchDelete() {
    yield takeEvery(OrderActionTypes.DELETE, remove);
}

export function* watchRates() {
    yield takeEvery(OrderActionTypes.RATES, rates);
}

export function* watchPreviewPaymentDetail() {
    yield takeEvery(OrderActionTypes.PREVIEW_PAYMENT_DETAIL, previewPaymentDetail);
}

export function* watchPaymentStep1() {
    yield takeEvery(OrderActionTypes.PAYMENT_STEP_1, paymentStep1);
}

export function* watchPaymentStep2() {
    yield takeEvery(OrderActionTypes.PAYMENT_STEP_2, paymentStep2);
}

export function* watchCreateBill() {
    yield takeEvery(OrderActionTypes.CREATE_BILL, createBill);
}

export function* watchUpdateBill() {
    yield takeEvery(OrderActionTypes.UPDATE_BILL, updateBill);
}

export function* watchOrderBills() {
    yield takeEvery(OrderActionTypes.ORDER_BILLS, orderBills);
}

export function* watchOrderBill() {
    yield takeEvery(OrderActionTypes.ORDER_BILL, orderBill);
}

export function* watchCreateNote() {
    yield takeEvery(OrderActionTypes.CREATE_NOTE, createNote);
}

function* orderSaga() {
    yield all([
        fork(watchOrders),
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchDelete),
        fork(watchRates),
        fork(watchPreviewPaymentDetail),
        fork(watchPaymentStep1),
        fork(watchPaymentStep2),
        fork(watchCreateBill),
        fork(watchUpdateBill),
        fork(watchOrderBills),
        fork(watchOrderBill),
        fork(watchCreateNote),
    ]);
}

export default orderSaga;
