import { WithdrawActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    withdraws: {},
    queryParams: {},

    withdraw: {},

    loading: false,
    error: "",
    validationErrors: {},
    selectedPermissions: [],

    withdrawChecked: false,

    withdrawInserted: false,

    withdrawApproved: false,

    withdrawUpdated: false,

    otpSent: false,
    otpSendLoading: false,
    otpSendError: "",

    searchModal: false,
    tmpWithdraws: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    previewDetail: {},
    previewDetailLoading: false,
    previewDetailError: "",

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
    totalWithdraws: {}
};

type WithdrawData = {
    id: number;
    name: string;
    guard_name: string;
};

type WithdrawQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    withdrawName: string;
};

type WithdrawsResult = {
    withdraws: WithdrawData | {},
    queryParams: WithdrawQueryParams
}

type WithdrawActionType = {
    type:
        | WithdrawActionTypes.API_RESPONSE_SUCCESS
        | WithdrawActionTypes.API_RESPONSE_ERROR
        | WithdrawActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | WithdrawActionTypes.GET_WITHDRAWS
        | WithdrawActionTypes.GET_WITHDRAW_REQUESTS
        | WithdrawActionTypes.GET_WITHDRAW
        | WithdrawActionTypes.CHECK_WITHDRAW
        | WithdrawActionTypes.INSERT_WITHDRAW
        | WithdrawActionTypes.VERIFY_WITHDRAW
        | WithdrawActionTypes.UPDATE_WITHDRAW
        | WithdrawActionTypes.RESET

        | WithdrawActionTypes.OPEN_SEARCH
        | WithdrawActionTypes.CLOSE_SEARCH
        | WithdrawActionTypes.SEARCH_WITHDRAW
        | WithdrawActionTypes.CLEAR_SEARCH

        | WithdrawActionTypes.CLEAR_CHECKED


        | WithdrawActionTypes.MANUAL_WITHDRAW
        | WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL

        | WithdrawActionTypes.EXPORT
        | WithdrawActionTypes.RESET_SEARCH
        | WithdrawActionTypes.TOTAL_WITHDRAW;

    payload: {
        actionType?: string;
        data?: WithdrawsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    withdraws?: WithdrawData | {};

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpWithdraws?: any;

    tmpQueryParams?: any;
};

const Withdraw = (state: State = INIT_STATE, action: WithdrawActionType) => {
    switch (action.type) {
        case WithdrawActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WithdrawActionTypes.GET_WITHDRAWS: {
                    return {
                        ...state,
                        withdraws: action.payload.data?.withdraws,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.GET_WITHDRAW_REQUESTS: {
                    return {
                        ...state,
                        withdraws: action.payload.data?.withdraws,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.GET_WITHDRAW: {
                    return {
                        ...state,
                        withdraw: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.CHECK_WITHDRAW: {
                    return {
                        ...state,
                        withdrawChecked: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.INSERT_WITHDRAW: {
                    return {
                        ...state,
                        withdrawInserted: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.VERIFY_WITHDRAW: {
                    return {
                        ...state,
                        withdrawApproved: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.UPDATE_WITHDRAW: {
                    return {
                        ...state,
                        withdrawUpdated: true,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.SEARCH_WITHDRAW: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpWithdraws: isEmptyObject(state.tmpWithdraws)? state.withdraws: state.tmpWithdraws,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        withdraws: action.payload.data?.withdraws,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case WithdrawActionTypes.MANUAL_WITHDRAW: {
                    return {
                        ...state,
                        withdrawInserted: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.TOTAL_WITHDRAW: {
                    return {
                        ...state,
                        totalWithdraws: action.payload.data,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        previewDetailLoading: false,
                    };
                }
                case WithdrawActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WithdrawActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WithdrawActionTypes.GET_WITHDRAWS:
                case WithdrawActionTypes.GET_WITHDRAW_REQUESTS:
                case WithdrawActionTypes.GET_WITHDRAW:
                case WithdrawActionTypes.CHECK_WITHDRAW:
                case WithdrawActionTypes.INSERT_WITHDRAW:
                case WithdrawActionTypes.VERIFY_WITHDRAW:
                case WithdrawActionTypes.UPDATE_WITHDRAW:
                case WithdrawActionTypes.MANUAL_WITHDRAW:
                case WithdrawActionTypes.TOTAL_WITHDRAW:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.SEARCH_WITHDRAW: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL: {
                    return {
                        ...state,
                        previewDetailError: action.payload.error,
                        previewDetailLoading: false,
                    };
                }
                case WithdrawActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WithdrawActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case WithdrawActionTypes.GET_WITHDRAWS:
                case WithdrawActionTypes.GET_WITHDRAW_REQUESTS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case WithdrawActionTypes.GET_WITHDRAW:
                case WithdrawActionTypes.CHECK_WITHDRAW:
                case WithdrawActionTypes.INSERT_WITHDRAW:
                case WithdrawActionTypes.VERIFY_WITHDRAW:
                case WithdrawActionTypes.UPDATE_WITHDRAW:
                case WithdrawActionTypes.MANUAL_WITHDRAW:
                case WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL:
                case WithdrawActionTypes.TOTAL_WITHDRAW:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case WithdrawActionTypes.SEARCH_WITHDRAW: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case WithdrawActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WithdrawActionTypes.GET_WITHDRAWS:
            return {
                ...state,
                withdraws: {},
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.GET_WITHDRAW_REQUESTS:
            return {
                ...state,
                // withdraws: {},
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.GET_WITHDRAW:
            return {
                ...state,
                withdraw: {},
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.CHECK_WITHDRAW:
            return {
                ...state,
                withdrawChecked: false,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.INSERT_WITHDRAW:
            return {
                ...state,
                withdrawInserted: false,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.VERIFY_WITHDRAW:
            return {
                ...state,
                withdrawApproved: false,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.UPDATE_WITHDRAW:
            return {
                ...state,
                withdrawUpdated: false,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.TOTAL_WITHDRAW:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.RESET:
            return {
                ...state,
                withdraws: {},
                queryParams: {},

                withdraw: {},

                loading: false,
                error: "",
                validationErrors: {},

                withdrawChecked: false,
                withdrawInserted: false,
                withdrawApproved: false,

                withdrawUpdated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpWithdraws: {},
                tmpQueryParams: {},

                otpSent: false,
                otpSendLoading: false,
                otpSendError: "",

                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
                totalWithdraws: {}
            };


        // search actions
        case WithdrawActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case WithdrawActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case WithdrawActionTypes.SEARCH_WITHDRAW:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case WithdrawActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                withdraws: state.tmpWithdraws,
                queryParams: state.tmpQueryParams,
                tmpWithdraws: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case WithdrawActionTypes.MANUAL_WITHDRAW:
            return {
                ...state,
                withdrawInserted: false,
                loading: true,
                error: "",
            };

        case WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: true,
                previewDetailError: "",
            };

        case WithdrawActionTypes.CLEAR_CHECKED:
            return {
                ...state,
                withdrawChecked: false
            };

        case WithdrawActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case WithdrawActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default Withdraw;
