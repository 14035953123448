import { BillActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    bills: {},
    bill: {},
    depositInsertedUrl: "",
    depositInserted: false,
};

type BillActionType = {
    type:
        | BillActionTypes.API_RESPONSE_SUCCESS
        | BillActionTypes.API_RESPONSE_ERROR
        | BillActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | BillActionTypes.BILLS
        | BillActionTypes.BILL
        | BillActionTypes.RESET
        | BillActionTypes.PAY_VIA_GATEWAY
        | BillActionTypes.PAY_VIA_WALLET;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    bills: any
};

const Bill = (state: State = INIT_STATE, action: BillActionType) => {
    switch (action.type) {
        case BillActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case BillActionTypes.BILLS: {
                    return {
                        ...state,
                        bills: action.payload.data,
                        loading: false,
                    };
                }

                case BillActionTypes.BILL: {
                    return {
                        ...state,
                        bill: action.payload.data,
                        loading: false,
                    };
                }
                case BillActionTypes.PAY_VIA_GATEWAY: {
                    return {
                        ...state,
                        depositInsertedUrl: action.payload.data,
                        loading: false,
                    };
                }
                case BillActionTypes.PAY_VIA_WALLET: {
                    return {
                        ...state,
                        depositInserted: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BillActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case BillActionTypes.BILLS:
                case BillActionTypes.BILL:
                case BillActionTypes.PAY_VIA_GATEWAY:
                case BillActionTypes.PAY_VIA_WALLET:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BillActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case BillActionTypes.BILLS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case BillActionTypes.BILL:
                case BillActionTypes.PAY_VIA_GATEWAY:
                case BillActionTypes.PAY_VIA_WALLET:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BillActionTypes.BILLS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case BillActionTypes.BILL:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case BillActionTypes.PAY_VIA_GATEWAY:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case BillActionTypes.PAY_VIA_WALLET:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case BillActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                bills: {},
                bill: {},
                depositInsertedUrl: "",
                depositInserted: false,
            };


        default:
            return { ...state };
    }
};

export default Bill;
